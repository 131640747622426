<template>
  <div>
        <div v-if="!isResultView"> <el-checkbox v-model="checkedAll" @change="changeCheckAll">全选</el-checkbox> </div> 
        <div class="list">
            <span @click="cangeItemCheck(index)" v-for="(item,index) in options" :key="index" :class="{'checked':(selectType || []).includes(item.value)}" :v-if="!isResultView||(selectType || []).includes(item.value)" > {{item.label}} </span>
            
        </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from 'lodash'
export default {
  name: 'multipleSelect',
 props: {
    // 类型
    listOpt: {
      type: Array,
      required: true
    },
      value: {
      type: [Number, String, Array],
    },
    isResultView:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      selectType:[],
      checkedAll:false,
      options: []
    }
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        this.selectType = newVal;
      },
      immediate: true //第一次进来触发
    },
  },
  computed: {
    ...mapGetters(["mid", "userInfo", "evidencePaymentPrice", "vipInfo"]),
  },
  mounted () {
    this.$nextTick(() => {
      this.options = _.cloneDeep(this.listOpt)
    })
    const savedData = localStorage.getItem(`form_${this.mid}`);
    if (savedData) {
      if(JSON.parse(savedData).rightHave.length == 17){
        this.checkedAll = true;
      }
    }
  },
  methods: {
      cangeItemCheck(idx){
          if(this.isResultView){
              return false;
          }
            const tempItemValue=this.options[idx].value
            if(this.selectType.includes(tempItemValue)){
                this.selectType=this.selectType.filter(item=>item!=tempItemValue)
                this.checkedAll=false
            }else{
                this.selectType.push(tempItemValue)
                if(this.selectType.length==this.options.length){
                     this.checkedAll=true
                }
            }
               console.log(JSON.stringify(this.selectType))
               this.$emit('input', this.selectType);
      },
      changeCheckAll(){
          if(this.checkedAll){
            this.initAllSelected()
          }else{
              this.selectType=[]
          }
             console.log(JSON.stringify(this.selectType))
           this.$emit('input', this.selectType);
      },
        initAllSelected () {
      // 默认选择全部
        if (this.options.length > 0) {
        this.selectType = this.options.map((item) => {
          return item.value
        })
      }
    },
  }
}
</script>

<style lang='scss' scoped>
    .list{
        span{
            display: inline-block;
            padding:4px 8px;
            color:rgba(0, 18, 22,.65);
            margin-right: 8px;
            line-height: 1em;
            border:solid 1px rgba($color: #001216, $alpha: .15);
            border-radius: 2px;
            cursor: pointer;
        }
        span:hover{
            border-color:#007DFF;
            color:#007DFF;
        }
        span.checked{
            background:#007DFF;
            border-color:#007DFF;
            color:#fff;
        }
    }
</style>
